export const findFirstEnabledConcept = (concepts = []) =>
  concepts.find(
    (concept) => concept.isEpubAvailable || concept.isNotesAvailable
  );

export const themes = {
  LIGHT: 'LIGHT',
  DARK: 'DARK'
};

export const DEFAULT_FONT_SIZE = 100;

// event constants
export const NOTES_SECTION_CLICKED = 'Notes - Notes Section Clicked';
export const NOTES_TOPIC_SELECTED = 'Notes - Topic Selected';
export const NOTES_CONCEPT_SELECTED = 'Notes - Concept Selected';
export const EPUB_NOTES = 'Epub Notes Page';
export const NOTES_TOPIC_DROPDOWN_SELECTED = 'Notes - Topic Dropdown Selected';
export const NOTES_SEARCH_CLICKED = 'Notes - Search Clicked';
export const NOTES_IMAGE_CLICKED = 'Notes - Image Clicked';
export const NOTES_IMAGE_VIEWED = 'Notes - Image Viewed';
export const NOTES_HIDE_NAVIGATION_CLICKED = 'Notes - Hide Navigation Clicked';
export const NOTES_TEXT_SETTINGS_HOVERED = 'Notes - Text Settings Hovered';
export const NOTES_THEME_CHANGED = 'Notes - Theme Changed';
export const NOTES_TEXT_SIZE_CHANGED = 'Notes - Text Size Changed';
export const NOTES_GET_STARTED_CLICKED = 'Notes - Get Started Clicked';
export const NOTES_PAGE_NAVIGATION_CLICKED = 'Notes - Page Navigation Clicked';
export const NOTES_SUB_ITEM_SELECTED = 'Notes - Sub item Selected';
export const NOTES_VIEWED = 'Notes - Viewed';

export const getTopicListEndpoint = (goalUID) =>
  `v1/topology/child/list/?goal_uid=${goalUID}&hide_empty_notes_tg=true&notes_type=epub`;

export const getConceptListEndpoint = (topicUID) =>
  `v1/topology/child/notes/?topology_uid=${topicUID}&notes_type=epub`;

export const getNotesEndpoint = (conceptUID, notesType = 'epub') =>
  `v1/topology/notes/?topology_uid=${conceptUID}&feed=notes_feed&notes_type=${notesType}`;
