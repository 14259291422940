import Icon from './NavIcon';

const SideNavPractice = (props) => (
  <Icon
    {...props}
    opacity=".3"
    gpath="M68 40C68 55.464 55.464 68 40 68C24.536 68 12 55.464 12 40C12 24.536 24.536 12 40 12C55.464 12 68 24.536 68 40Z"
    path="M48.6171 9.68425C48.6171 9.00906 48.1725 8.39913 47.4875 8.13464C46.8025 7.87015 46.0095 8.00223 45.4728 8.47022L38.869 14.2288C29.0364 22.8028 20.7282 32.7066 14.24 43.5878C14.0872 43.8325 14 44.1152 14 44.4165C14 45.3466 14.8315 46.1007 15.8571 46.1007H31.8657V70.3158C31.8657 70.9827 32.2997 71.5868 32.9724 71.8563C33.6451 72.1258 34.4298 72.01 34.9735 71.5608L37.9283 69.1199C48.2715 60.5753 57.0492 50.5922 63.9321 39.5453L65.7597 36.6119C66.0846 36.0905 66.0797 35.4518 65.7469 34.9345C65.414 34.4172 64.8033 34.0992 64.1429 34.0992H48.6171V9.68425Z"
  />
);

export default SideNavPractice;
