/* eslint-disable max-lines */
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { getIsLoggedIn } from '@api/selectors/auth';
import useSidenavFlags from '@api/hooks/useSidenavFlags';
import {
  getIsPracticeAvailable,
  getIsNoTestGoal,
  getIsSyllabusV1Enabled
} from '@api/selectors/topology';
import useTopology from '@api/hooks/topology/useTopology';
import { Spacings } from '@styles/index';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { sideNavFlags as sideNavFlagsConst } from '@constants/index';
import cookies from '@utils/cookies';
import {
  getIsCompeteEnabled,
  getIsGTPGoal,
  getIsTestRevampGoal
} from '@api/selectors/constants';
import useConstants from '@api/hooks/useConstants';
import algos from '@utils/algos';
import NavNotes from '@icon/notes/NavNotes';
import PrintedNotesIcon from '@cont/AddressCollection/PrintedNotes/PrintedNotesIcon';
import { localStorageAvailable } from '@utils/localStorage';
import {
  isPrintedNotesEnabled,
  printedNotesPageName
} from '@cont/AddressCollection/constants';
import { getIsSubscribedToLite } from '@api/selectors/subscription';
import useMounted from '@hooks/useMounted';
import { StyH7 } from './CommonComps';
import plusNavBarItems from './plusNavBarItems';
import { getIsBlueDotshownForTestRevamp, goalHref } from './commonUtils';
import { Item } from './NavBarItems';
import SideNavPractice from './PracticeIcon';
import TestsIcon from './TestsIcon';
import SyllabusIcon from './SyllabusIcon';
import DoubtsIcon from './DoubtsIcon';
import BrowseIcon from './BrowseIcon';
import OtherCoursesIcon from './OtherCoursesIcon';
import FreeLiveClassesIcon from './FreeLiveClassesIcon';
import LiveMentoringIcon from './LiveMentoringIcon';
import SideNavSaved from './SavedIcon';
import CompeteIcon from './CompeteIcon';

const StySideNavPractice = styled(SideNavPractice)`
  margin-right: ${Spacings.SPACING_3B};
`;

const StyBrowseIcon = styled(BrowseIcon)`
  margin-right: ${Spacings.SPACING_3B};
`;

const StyTestsIcon = styled(TestsIcon)`
  margin-right: ${Spacings.SPACING_3B};
`;

const StySyllabusIcon = styled(SyllabusIcon)`
  margin-right: ${Spacings.SPACING_3B};
`;

const StyNotesIcon = styled(NavNotes)`
  margin-right: ${Spacings.SPACING_3B};
`;

const StyDoubtsIcon = styled(DoubtsIcon)`
  margin-right: ${Spacings.SPACING_3B};
`;

const StyFreeLiveClassesIcon = styled(FreeLiveClassesIcon)`
  margin-right: ${Spacings.SPACING_3B};
`;

const StyPrintedNotesIcon = styled(PrintedNotesIcon)`
  margin-right: ${Spacings.SPACING_3B};
`;

const StyOtherCoursesIcon = styled(OtherCoursesIcon)`
  margin-right: ${Spacings.SPACING_3B};
`;
const StyLiveMentoring = styled(LiveMentoringIcon)`
  margin-right: ${Spacings.SPACING_3B};
`;

const StySideNavSaved = styled(SideNavSaved)`
  margin-right: ${Spacings.SPACING_3B};
`;

const StyCompete = styled(CompeteIcon)`
  margin-right: ${Spacings.SPACING_3B};
`;

const practiceUrls = [
  `${goalHref}/practice`,
  `${goalHref}/practice/topics`,
  `${goalHref}/practice/topics/[topicUID]`,
  `${goalHref}/practice/topics/[topicUID]/concept/[conceptUID]`
];

const IconDot = styled.span`
  width: ${Spacings.SPACING_2B};
  height: ${Spacings.SPACING_2B};
  border-radius: 50%;
  background-color: var(--color-i-blue);
  position: absolute;
  top: 25%;
  left: 12%;
  border: 1.6px solid var(--color-text-tertiary);
  &:hover {
    background-color: var(--color-i-blue) !important;
  }
`;

const getSelfStudyMap = ({
  route,
  goalUID,
  handleClick,
  isPracticeAvailable,
  isCompeteEnabled,
  isNoTestGoal,
  hasPlusSubscription,
  isSubscribedToLite,
  liveMentoringEnabled,
  isCatalogueFeatureAvailable,
  selectedNav,
  goalAs,
  isK12,
  isAADEnabledFlag,
  isNotesEnabledFlag,
  isLoggedIn,
  isSyllabusV1Enabled,
  isGTP,
  showBlueDotForTestRevamp,
  isRecordedGoal,
  isPrintedNotesEnabledFlag,
  hasOfflineSubscription,
  hasCentreSubscription
}) => {
  const isPracticeURL = practiceUrls.includes(route);

  const isTestSeriesURL = route === `${goalHref}/test-series`;

  const isCompeteURL = route === `${goalHref}/compete`;

  const isDoubtsAndSolutionVisited = cookies.readCookie(
    'doubtsAndSolutionVisited',
    false
  );
  const isSyllabusVisited = cookies.readCookie('syllabusVisited', false);
  const isNotesVisited = cookies.readCookie('notesVisited', false);

  const isPrintedNotesVisited = localStorageAvailable()
    ? localStorage.getItem('isPrintedNotesVisited')
    : false;
  const practiceHref =
    hasPlusSubscription ||
    isSubscribedToLite ||
    (hasOfflineSubscription && !hasCentreSubscription)
      ? `${goalHref}/practice`
      : `${goalHref}/practice/topics`;
  const practiceAs =
    hasPlusSubscription ||
    isSubscribedToLite ||
    (hasOfflineSubscription && !hasCentreSubscription)
      ? `${goalAs}/practice`
      : `${goalAs}/practice/topics`;

  const selfStudyMap = [
    {
      label: 'Browse',
      dataId: plusNavBarItems.BROWSE,
      isSelected:
        route === `${goalHref}/browse` ||
        selectedNav === plusNavBarItems.BROWSE,
      href: `${goalHref}/browse`,
      as: `${goalAs}/browse`,
      itemHandleClick: handleClick(
        plusNavBarItems.BROWSE,
        `${goalHref}/browse`,
        `${goalAs}/browse`
      ),
      key: plusNavBarItems.BROWSE,
      isShown: true,
      icon: StyBrowseIcon,
      hovColor: 'var(--color-i-turquoise-6)'
    },
    {
      label: 'Compete',
      dataId: plusNavBarItems.COMPETE,
      isSelected: isCompeteURL || selectedNav === plusNavBarItems.COMPETE,
      href: `${goalHref}/compete`,
      as: `${goalAs}/compete`,
      itemHandleClick: handleClick(
        plusNavBarItems.COMPETE,
        `${goalHref}/compete`,
        `${goalAs}/compete`
      ),
      key: plusNavBarItems.COMPETE,
      isShown: isCompeteEnabled,
      icon: StyCompete,
      hovColor: 'var(--color-i-blue-20)'
    },
    {
      label: goalUID === 'SDDOC' ? plusNavBarItems.Q_BANK : 'Practice',
      dataId: plusNavBarItems.PRACTICE,
      isSelected: isPracticeURL,
      href: practiceHref,
      as: practiceAs,
      itemHandleClick: handleClick(
        plusNavBarItems.PRACTICE,
        practiceHref,
        practiceAs
      ),
      key: plusNavBarItems.PRACTICE,
      isShown: isPracticeAvailable,
      icon: StySideNavPractice,
      hovColor: 'var(--color-i-violet-12)'
    },
    {
      label: 'Tests',
      dataId: plusNavBarItems.TEST_SERIES,
      isSelected: isTestSeriesURL,
      href: `${goalHref}/test-series`,
      as: `${goalAs}/test-series`,
      itemHandleClick: handleClick(
        plusNavBarItems.TEST_SERIES,
        `${goalHref}/test-series`,
        `${goalAs}/test-series`
      ),
      key: plusNavBarItems.TEST_SERIES,
      isShown: !isNoTestGoal,
      icon: StyTestsIcon,
      showDot: showBlueDotForTestRevamp,
      hovColor: 'var(--color-i-blue-20)'
    },
    {
      label: isRecordedGoal ? 'Video Lessons' : plusNavBarItems.PLAYLIST,
      dataId: plusNavBarItems.SYLLABUS,
      isSelected: selectedNav === plusNavBarItems.SYLLABUS,
      href: `${goalHref}/topics`,
      as: `${goalAs}/topics`,
      itemHandleClick: handleClick(
        isRecordedGoal
          ? plusNavBarItems.VIDEO_LESSONS
          : plusNavBarItems.SYLLABUS,
        `${goalHref}/topics`,
        `${goalAs}/topics`
      ),
      key: plusNavBarItems.SYLLABUS,
      isShown: true,
      icon: StySyllabusIcon,
      showDot: isSyllabusV1Enabled && !isSyllabusVisited,
      hovColor: 'var(--color-i-green-8)'
    },
    {
      label: 'Doubts & solutions',
      dataId: plusNavBarItems.DOUBTS_AND_SOLUTIONS,
      isSelected: selectedNav === plusNavBarItems.DOUBTS_AND_SOLUTIONS,
      href: `${goalHref}/doubts-and-solutions`,
      as: `${goalAs}/doubts-and-solutions`,
      itemHandleClick: handleClick(
        plusNavBarItems.DOUBTS_AND_SOLUTIONS,
        `${goalHref}/doubts-and-solutions`,
        `${goalAs}/doubts-and-solutions`
      ),
      key: plusNavBarItems.DOUBTS_AND_SOLUTIONS,
      isShown:
        !isLoggedIn ||
        isCatalogueFeatureAvailable ||
        isAADEnabledFlag ||
        isSubscribedToLite,
      icon: StyDoubtsIcon,
      showDot: !isDoubtsAndSolutionVisited,
      hovColor: 'var(--color-i-green-9)'
    },
    {
      label: 'Notes',
      dataId: plusNavBarItems.NOTES,
      isSelected: selectedNav === plusNavBarItems.NOTES,
      href: `${goalHref}/notes`,
      as: `${goalAs}/notes`,
      itemHandleClick: handleClick(
        plusNavBarItems.NOTES,
        `${goalHref}/notes`,
        `${goalAs}/notes`
      ),
      key: plusNavBarItems.NOTES,
      isShown:
        isLoggedIn &&
        (hasPlusSubscription || hasOfflineSubscription) &&
        isNotesEnabledFlag,
      icon: StyNotesIcon,
      showDot: !isNotesVisited,
      hovColor: 'var(--color-i-yellow-4)'
    },
    {
      label: 'Free live classes',
      dataId: plusNavBarItems.FREE_CLASSES_AND_TESTS,
      isSelected: selectedNav === plusNavBarItems.FREE_CLASSES_AND_TESTS,
      href: `${goalHref}/classes`,
      as: `${goalAs}/classes`,
      itemHandleClick: handleClick(
        plusNavBarItems.FREE_CLASSES_AND_TESTS,
        `${goalHref}/classes`,
        `${goalAs}/classes`
      ),
      key: plusNavBarItems.FREE_CLASSES_AND_TESTS,
      isShown: true,
      icon: StyFreeLiveClassesIcon,
      hovColor: 'var(--color-i-red-1)'
    },
    {
      label: isK12 ? 'Live homework help' : '1:1 Live mentorship',
      dataId: plusNavBarItems.ONE_TO_ONE_LIVE_MENTORSHIP,
      isSelected: selectedNav === plusNavBarItems.ONE_TO_ONE_LIVE_MENTORSHIP,
      href: `${goalHref}/one-to-one-livementorship`,
      as: `${goalAs}/one-to-one-livementorship`,
      itemHandleClick: handleClick(
        plusNavBarItems.ONE_TO_ONE_LIVE_MENTORSHIP,
        `${goalHref}/one-to-one-livementorship`,
        `${goalAs}/one-to-one-livementorship`
      ),
      linkHandleClick: handleClick(plusNavBarItems.ONE_TO_ONE_LIVE_MENTORSHIP),
      hasPlusSubscription,
      key: plusNavBarItems.ONE_TO_ONE_LIVE_MENTORSHIP,
      isShown: liveMentoringEnabled,
      icon: StyLiveMentoring,
      hovColor: 'var(--color-i-blue-18)'
    },
    {
      label: printedNotesPageName,
      dataId: plusNavBarItems.PRINTED_NOTES,
      isSelected: selectedNav === plusNavBarItems.PRINTED_NOTES,
      href: `${goalHref}/address-collection`,
      as: `${goalAs}/address-collection`,
      itemHandleClick: handleClick(
        plusNavBarItems.PRINTED_NOTES,
        `${goalHref}/address-collection`,
        `${goalAs}/address-collection`
      ),
      key: plusNavBarItems.PRINTED_NOTES,
      isShown: isLoggedIn && isPrintedNotesEnabledFlag,
      icon: StyPrintedNotesIcon,
      showDot: !isPrintedNotesVisited,
      hovColor: 'var(--color-i-yellow-7)'
    },
    {
      label: 'Other courses',
      dataId: plusNavBarItems.FREE_PLATFORM,
      isSelected: selectedNav === plusNavBarItems.FREE_PLATFORM,
      href: `${goalHref}/free-platform`,
      as: `${goalAs}/free-platform`,
      itemHandleClick: handleClick(
        plusNavBarItems.FREE_PLATFORM,
        `${goalHref}/free-platform`,
        `${goalAs}/free-platform`
      ),
      key: plusNavBarItems.FREE_PLATFORM,
      isShown: !(hasPlusSubscription || hasOfflineSubscription),
      icon: StyOtherCoursesIcon,
      hovColor: 'var(--color-i-gray-17)'
    }
  ];

  const gtpSelfStudyMap = [
    {
      label: 'Browse',
      dataId: plusNavBarItems.BROWSE,
      isSelected:
        route === `${goalHref}/browse` ||
        selectedNav === plusNavBarItems.BROWSE,
      href: `${goalHref}/browse`,
      as: `${goalAs}/browse`,
      itemHandleClick: handleClick(
        plusNavBarItems.BROWSE,
        `${goalHref}/browse`,
        `${goalAs}/browse`
      ),
      key: plusNavBarItems.BROWSE,
      isShown: true,
      icon: StyBrowseIcon,
      hovColor: 'var(--color-i-turquoise-6)'
    },
    {
      label: 'Video lessons',
      dataId: plusNavBarItems.SYLLABUS,
      isSelected: selectedNav === plusNavBarItems.SYLLABUS,
      href: `${goalHref}/topics`,
      as: `${goalAs}/topics`,
      itemHandleClick: handleClick(
        plusNavBarItems.SYLLABUS,
        `${goalHref}/topics`,
        `${goalAs}/topics`
      ),
      key: plusNavBarItems.SYLLABUS,
      isShown: true,
      icon: StyFreeLiveClassesIcon,
      showDot: isSyllabusV1Enabled && !isSyllabusVisited,
      hovColor: 'var(--color-i-red-1)'
    },
    {
      label: 'Practice',
      dataId: plusNavBarItems.PRACTICE,
      isSelected: isPracticeURL,
      href: practiceHref,
      as: practiceAs,
      itemHandleClick: handleClick(
        plusNavBarItems.PRACTICE,
        practiceHref,
        practiceAs
      ),
      key: plusNavBarItems.PRACTICE,
      isShown: true,
      icon: StySideNavPractice,
      hovColor: 'var(--color-i-violet-12)'
    },
    {
      label: 'Tests',
      dataId: plusNavBarItems.TEST_SERIES,
      isSelected: isTestSeriesURL,
      href: `${goalHref}/test-series`,
      as: `${goalAs}/test-series`,
      itemHandleClick: handleClick(
        plusNavBarItems.TEST_SERIES,
        `${goalHref}/test-series`,
        `${goalAs}/test-series`
      ),
      key: plusNavBarItems.TEST_SERIES,
      isShown: true,
      icon: StyTestsIcon,
      hovColor: 'var(--color-i-blue-20)'
    },
    {
      label: 'Saved',
      dataId: plusNavBarItems.SAVED,
      isSelected: selectedNav === plusNavBarItems.SAVED,
      href: `${goalHref}/saved`,
      as: `${goalAs}/saved`,
      itemHandleClick: handleClick(
        plusNavBarItems.SAVED,
        `${goalHref}/saved`,
        `${goalAs}/saved`
      ),
      key: plusNavBarItems.SAVED,
      isShown: hasPlusSubscription,
      hovColor: 'var(--color-i-orange-11)',
      icon: StySideNavSaved
    }
  ];

  if (isRecordedGoal) {
    return algos.moveArrayElement(selfStudyMap, 3, 1);
  }

  return isGTP ? gtpSelfStudyMap : selfStudyMap;
};

const isLiveMentoringEnabled = (array = []) => {
  const ele = array.find(
    (feature) => feature.type === sideNavFlagsConst.ONE_TO_ONE_LIVE_MENTORING
  );
  return !!ele;
};

const isAADEnabled = (array = []) => {
  const ele = array.find(
    (feature) => feature.type === sideNavFlagsConst.DOUBTS_AND_SOLUTIONS
  );
  const ele2 = ele?.isAskADoubtEnabled;
  return !!ele2;
};

const isNotesEnabled = (array = []) => {
  const ele = array.find((feature) => feature.type === sideNavFlagsConst.NOTES);
  return !!ele;
};

const SelfStudy = ({
  handleClick,
  selectedNav,
  goalUID,
  goalAs,
  hasPlusSubscription,
  isCatalogueFeatureAvailable,
  hasOfflineSubscription,
  hasCentreSubscription
}) => {
  const { route } = useRouter();
  const isGTP = useConstants(getIsGTPGoal(goalUID));
  const { topology: goalInfo } = useTopology(goalUID);
  const isPracticeAvailable = getIsPracticeAvailable(goalInfo) || isGTP;
  const isNoTestGoal = getIsNoTestGoal(goalInfo);
  const isSyllabusV1Enabled = getIsSyllabusV1Enabled(goalInfo);

  const { sidenavFlags } = useSidenavFlags(goalUID);
  const { features } = sidenavFlags || { features: [] };

  const { isK12, isRecordedGoal } = goalInfo;
  const liveMentoringEnabled = isLiveMentoringEnabled(features);
  const isAADEnabledFlag = isAADEnabled(features);
  const isNotesEnabledFlag = isNotesEnabled(features);
  const isPrintedNotesEnabledFlag = isPrintedNotesEnabled(features);
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const isSubscribedToLite = useMyInfo(getIsSubscribedToLite(goalUID));
  const isTestRevampGoal = useConstants(getIsTestRevampGoal(goalUID));
  const isCompeteEnabled = useConstants(getIsCompeteEnabled(goalUID));
  const showBlueDotForTestRevamp =
    getIsBlueDotshownForTestRevamp(isTestRevampGoal);

  const tabsMap = getSelfStudyMap({
    route,
    goalUID,
    handleClick,
    isPracticeAvailable,
    isCompeteEnabled,
    isNoTestGoal,
    hasPlusSubscription,
    isSubscribedToLite,
    liveMentoringEnabled,
    isCatalogueFeatureAvailable,
    selectedNav,
    goalAs,
    isK12,
    isAADEnabledFlag,
    isNotesEnabledFlag,
    isLoggedIn,
    isSyllabusV1Enabled,
    isGTP,
    showBlueDotForTestRevamp,
    isRecordedGoal,
    isPrintedNotesEnabledFlag,
    hasOfflineSubscription,
    hasCentreSubscription
  });

  const isMounted = useMounted();

  return (
    <>
      {!isGTP && (
        <StyH7 $isLite={isSubscribedToLite || isRecordedGoal}>SELF STUDY</StyH7>
      )}
      {tabsMap
        .filter((item) => item && item.isShown)
        .map((item) => {
          return (
            <Item
              $selected={item.isSelected}
              data-id={item.dataId}
              hovColor={item.hovColor}
              key={item.key}
              href={item.href}
              as={item.as}
              onClick={item.itemHandleClick}
              prefetch
            >
              {isMounted && item.showDot && <IconDot />}
              {item.icon ? (
                <item.icon
                  size="20px"
                  color={
                    item.isSelected
                      ? item.hovColor
                      : 'var(--color-text-secondary)'
                  }
                  className="sideNavIcon"
                />
              ) : null}
              {item.label}
            </Item>
          );
        })}
    </>
  );
};

export default SelfStudy;

SelfStudy.propTypes = {
  handleClick: PropTypes.func.isRequired,
  selectedNav: PropTypes.string,
  goalUID: PropTypes.string.isRequired
};
SelfStudy.defaultProps = {
  selectedNav: ''
};
