const mergeTwoSortedArray = (array11 = [], array22 = []) => {
  const newArray = [];
  let index1 = 0;
  let index2 = 0;
  const length1 = array11.length;
  const length2 = array22.length;
  const array1 = array11.map((item, index) => ({ ...item, index }));
  const array2 = array22.map((item, index) => ({ ...item, index }));

  while (index1 <= length1 - 1 || index2 <= length2 - 1)
    if (array1.length === index1 || array2.length === index2) {
      if (array1.length === index1) {
        newArray.push(...array2.slice(index2));
        index2 = length2;
      } else {
        newArray.push(...array1.slice(index1));
        index1 = length1;
      }
    } else if (array1[index1].rank <= array2[index2].rank) {
      newArray.push(array1[index1]);
      index1 += 1;
    } else {
      newArray.push(array2[index2]);
      index2 += 1;
    }
  return newArray;
};

const moveArrayElement = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    // eslint-disable-next-line no-plusplus
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

const algos = {
  mergeTwoSortedArray,
  moveArrayElement
};

export default algos;
