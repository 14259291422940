import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Spacings } from '@styles/index';
import PlannerIcon from './PlannerIcon';
import { Item } from './NavBarItems';
import plusNavBarItems from './plusNavBarItems';
import { goalHref } from './commonUtils';

const StyPlannerIcon = styled(PlannerIcon)`
  margin-right: ${Spacings.SPACING_3B};
`;

const StyledItem = styled(Item)`
  margin-bottom: 0;
`;

const itemHoverColor = 'var(--color-i-red-1)';

const PlannerItem = ({ handleClick, isSelected, goalAs }) => {
  const plannerHref = `${goalHref}/planner`;
  const as = `${goalAs}/planner`;
  return (
    <StyledItem
      $selected={isSelected}
      data-id={plusNavBarItems.SCHEDULE}
      onClick={handleClick(plusNavBarItems.SCHEDULE, plannerHref, as)}
      hovColor={itemHoverColor}
      href={plannerHref}
      as={as}
      prefetch
    >
      <StyPlannerIcon
        size="20px"
        color={isSelected ? itemHoverColor : 'var(--color-text-secondary)'}
        className="sideNavIcon"
      />
      Planner
    </StyledItem>
  );
};

PlannerItem.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  goalAs: PropTypes.string.isRequired
};

export default PlannerItem;
