import Icon from './NavIcon';

const HomeIcon = (props) => (
  <Icon
    {...props}
    opacity=".3"
    gpath="M56 12C53.7909 12 52 13.7909 52 16V40C52 42.2091 53.7909 44 56 44H64C66.2091 44 68 42.2091 68 40V16C68 13.7909 66.2091 12 64 12H56Z M32 48C29.7909 48 28 49.7909 28 52V68H52V52C52 49.7909 50.2091 48 48 48H32Z"
    path="M34.5342 14.1681C37.6099 11.2903 42.3901 11.2903 45.4658 14.1681L65.4658 32.881C67.0825 34.3936 68 36.5086 68 38.7227V59.9997C68 64.418 64.4183 67.9997 60 67.9997H49.6C48.7163 67.9997 48 67.2833 48 66.3997V56.0003C48 51.5821 44.4183 48.0003 40 48.0003C35.5817 48.0003 32 51.5821 32 56.0003V66.3997C32 67.2833 31.2837 67.9997 30.4 67.9997H20C15.5817 67.9997 12 64.418 12 59.9997V38.7227C12 36.5086 12.9175 34.3936 14.5342 32.881L34.5342 14.1681Z"
  />
);

export default HomeIcon;
