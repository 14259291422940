import Icon from './Icon';

const QuestionMark = (props) => (
  <Icon
    {...props}
    viewBox="0 0 10 16"
    path="M4.76676 3.2C4.1233 3.2 3.55893 3.51875 3.25467 4.00117C2.77227 4.76606 1.70516 5.02744 0.871227 4.58498C0.0372934 4.1425 -0.247674 3.16373 0.234726 2.39883C1.13695 0.968288 2.82755 0 4.76676 0C7.65708 0 10 2.14904 10 4.8C10 6.88995 8.54376 8.668 6.51117 9.32688V9.6C6.51117 10.4837 5.7302 11.2 4.76676 11.2C3.80338 11.2 3.02237 10.4837 3.02237 9.6V8C3.02237 7.11635 3.80338 6.4 4.76676 6.4C5.7302 6.4 6.51117 5.68365 6.51117 4.8C6.51117 3.91635 5.7302 3.2 4.76676 3.2ZM4.76676 16C5.7302 16 6.51117 15.2837 6.51117 14.4C6.51117 13.5163 5.7302 12.8 4.76676 12.8C3.80336 12.8 3.02235 13.5163 3.02235 14.4C3.02235 15.2837 3.80336 16 4.76676 16Z"
  />
);

export default QuestionMark;
