import Icon from './NavIcon';

const PlannerIcon = (props) => (
  <Icon
    {...props}
    opacity=".3"
    cx="60"
    cy="56"
    r="16"
    path="M26.7192 16.2584C26.7192 15.0111 25.6699 14 24.3755 14C23.0811 14 22.0318 15.0111 22.0318 16.2584V21.7279C17.3661 22.3635 13.6801 25.9195 13.0561 30.4555L12.7856 32.4216C12.7398 32.7549 12.6959 33.0885 12.6541 33.4222C12.5431 34.3073 13.2669 35.0781 14.1922 35.0781H65.8078C66.7331 35.0781 67.4569 34.3073 67.3459 33.4222C67.3041 33.0885 67.2602 32.7549 67.2144 32.4216L66.9439 30.4554C66.3199 25.9195 62.634 22.3636 57.9684 21.7279V16.2584C57.9684 15.0111 56.9191 14 55.6247 14C54.3303 14 53.281 15.0111 53.281 16.2584V21.2799C44.4446 20.5216 35.5556 20.5216 26.7192 21.2798V16.2584Z"
    path2="M67.9511 41.0267C67.9238 40.2245 67.2358 39.5948 66.4028 39.5948H13.5972C12.7642 39.5948 12.0762 40.2245 12.0489 41.0267C11.8633 46.4699 12.2064 51.9242 13.0774 57.318C13.7368 61.4012 17.1794 64.5375 21.4472 64.9431L25.1753 65.2973C35.0345 66.2342 44.9655 66.2342 54.8247 65.2973L58.5528 64.9431C62.8206 64.5375 66.2632 61.4012 66.9226 57.318C67.7936 51.9242 68.1367 46.4699 67.9511 41.0267Z"
  />
);

export default PlannerIcon;
