import Icon from './NavIcon';

const CompeteIcon = (props) => {
  return (
    <Icon width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <g clipPath="url(#clip0_2412_287251)">
        <circle opacity="0.3" cx="10" cy="10" r="7" fill="#7A8B94" />
        <ellipse
          cx="9.29728"
          cy="10.0688"
          rx="8.75"
          ry="3.75"
          transform="rotate(-150 9.29728 10.0688)"
          stroke="#7A8B94"
          strokeWidth="1"
          style={{ fill: 'transparent' }}
        />
        <circle cx="16.25" cy="3.75" r="1.25" fill="#7A8B94" />
        <circle cx="2.25684" cy="15.7803" r="1.25" fill="#7A8B94" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6923 5.4763C17.436 5.69075 17.1311 5.84917 16.7966 5.93273C16.8921 6.20726 16.8991 6.54937 16.7901 6.96709C16.6526 7.49446 16.3375 8.10625 15.8456 8.76368C14.8631 10.077 13.2352 11.4924 11.1869 12.675C9.13861 13.8576 7.09887 14.5597 5.47025 14.7539C5.03969 14.8053 4.64481 14.8203 4.29061 14.8017C4.42466 15.0899 4.49951 15.4111 4.49951 15.7498C4.49951 15.7695 4.49926 15.7892 4.49875 15.8088C4.84646 15.8133 5.21145 15.7919 5.58867 15.7469C7.38126 15.5331 9.55011 14.7747 11.6869 13.541C13.8237 12.3073 15.5649 10.8083 16.6463 9.36272C17.1865 8.64074 17.5771 7.91202 17.7578 7.21954C17.9152 6.61608 17.9186 6.01374 17.6923 5.4763ZM14.3243 4.91487C14.1118 4.92174 13.888 4.93893 13.6535 4.96689C12.0249 5.16113 9.98518 5.86324 7.93689 7.04581C5.88861 8.22839 4.2607 9.6438 3.27817 10.9571C2.78632 11.6145 2.47123 12.2263 2.33364 12.7537C2.25994 13.0362 2.23929 13.2841 2.26316 13.4999L2.24951 13.4998C1.90368 13.4998 1.57607 13.5778 1.28332 13.7172C1.22274 13.326 1.25836 12.9139 1.36603 12.5012C1.5467 11.8088 1.93731 11.0801 2.47745 10.3581C3.5589 8.91254 5.30012 7.41346 7.43689 6.17979C9.57367 4.94612 11.7425 4.18773 13.5351 3.97393C13.6946 3.95491 13.8519 3.9401 14.0066 3.92969C14.035 4.28832 14.1475 4.62329 14.3243 4.91487Z"
          fill="#7A8B94"
          strokeWidth="0"
        />
      </g>
      <defs>
        <clipPath id="clip0_2412_287251">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default CompeteIcon;
