/* eslint-disable react-hooks/exhaustive-deps */
import useFetch from '@hooks/useFetch';
import { HOST_TYPE } from '@utils/api-helper/host';

const useUserProfileInfo = (
  goalUID,
  options = { selector: null, fallbackData: undefined }
) => {
  const key = `v2/subscriptions/user/profile/?goal_uid=${goalUID}`;

  const {
    data: userProfileInfo,
    isValidating,
    ...rest
  } = useFetch(
    goalUID ? key : null,
    { hostType: HOST_TYPE.BACKEND_API_HOST },
    { fallbackData: options.fallbackData }
  );

  if (options.selector && typeof options.selector === 'function')
    return options.selector(userProfileInfo || {});
  return {
    key,
    userProfileInfo,
    isValidating,
    ...rest
  };
};

export default useUserProfileInfo;
