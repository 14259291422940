import create from 'zustand';
import createStoreSelectors from './utils/createStoreSelector';

const useStore = create((set) => ({
  showLoginDrawer: false,
  loginDrawerCtaName: '',
  setShowLoginDrawer: (value, name) => {
    set((state) => ({
      ...state,
      showLoginDrawer: value,
      loginDrawerCtaName: name
    }));
  }
}));

const useShowLoginDrawerOnBatch = createStoreSelectors(useStore);

export default useShowLoginDrawerOnBatch;
