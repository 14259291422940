import PropTypes from 'prop-types';
import { Spacings } from '@styles/index';

const Icon = ({
  width,
  height,
  color,
  onClick,
  className,
  path,
  path2,
  size,
  children,
  viewBox,
  pathFillRule,
  pathClipRule,
  gpathFillRule,
  gpathClipRule,
  pathFillRule2,
  pathClipRule2,
  stroke,
  strokeWidth,
  strokeLinecap,
  strokeLinejoin,
  opacity,
  gpath,
  cx,
  cy,
  r,
  rectx,
  recty,
  rectWidth,
  rectHeight,
  rx
}) => (
  <svg
    width={size || width}
    height={size || height}
    viewBox={viewBox}
    onClick={onClick}
    className={className}
  >
    {rectx || recty || rectWidth || rectHeight ? (
      <rect
        opacity="0.3"
        x={rectx}
        y={recty}
        width={rectWidth}
        height={rectHeight}
        rx={rx}
        fill={color}
      />
    ) : null}

    {cx || cy || r ? (
      <circle opacity="0.3" cx={cx} cy={cy} r={r} fill={color} />
    ) : null}
    {opacity && gpath ? (
      <g opacity={opacity}>
        <path
          d={gpath}
          fill={color}
          fillRule={gpathFillRule}
          clipRule={gpathClipRule}
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
          className={className}
        />
      </g>
    ) : null}
    {path && (
      <path
        d={path}
        fill={color}
        fillRule={pathFillRule}
        clipRule={pathClipRule}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        className={className}
      />
    )}
    {path2 && (
      <path
        d={path2}
        fill={color}
        fillRule={pathFillRule2}
        clipRule={pathClipRule2}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
        className={className}
      />
    )}
    {children}
  </svg>
);

export default Icon;

Icon.propTypes = {
  path: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  viewBox: PropTypes.string,
  pathFillRule: PropTypes.string,
  pathClipRule: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.string,
  strokeLinecap: PropTypes.string,
  strokeLinejoin: PropTypes.string,
  gpathFillRule: PropTypes.string,
  gpathClipRule: PropTypes.string
};

Icon.defaultProps = {
  path: null,
  color: 'var(--color-base-fill)',
  size: Spacings.SPACING_3B,
  className: '',
  width: Spacings.SPACING_3B,
  height: Spacings.SPACING_3B,
  onClick: () => {},
  children: null,
  viewBox: '0 0 80 80',
  pathFillRule: 'unset',
  pathClipRule: 'unset',
  gpathFillRule: 'unset',
  gpathClipRule: 'unset',
  stroke: null,
  strokeWidth: null,
  strokeLinecap: 'butt',
  strokeLinejoin: 'miter'
};
