import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Spacings } from '@styles/index';
import SideNavHome from './HomeIcon';
import { Item } from './NavBarItems';
import plusNavBarItems from './plusNavBarItems';
import { goalHref } from './commonUtils';

const StySideNavHome = styled(SideNavHome)`
  margin-right: ${Spacings.SPACING_3B};
`;

const hovColor = 'var(--color-i-blue-18)';

const Home = ({ handleClick, isSelected, goalAs }) => {
  return (
    <Item
      $selected={isSelected}
      data-id={plusNavBarItems.PLUS_HOME}
      onClick={handleClick(
        plusNavBarItems.PLUS_HOME,
        false,
        true,
        goalHref,
        goalAs
      )}
      hovColor={hovColor}
      href={goalHref}
      as={goalAs}
      prefetch
    >
      <StySideNavHome
        size="20px"
        color={isSelected ? hovColor : 'var(--color-text-secondary)'}
        className="sideNavIcon"
      />
      Home
    </Item>
  );
};

export default Home;
Home.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  goalAs: PropTypes.string.isRequired
};
