import Icon from './NavIcon';

const SavedIcon = (props) => (
  <Icon
    {...props}
    opacity=".3"
    gpath="M60.063 13.0376C52.091 11.6541 43.9091 11.6541 35.937 13.0376C33.0245 13.543 30.7051 15.8574 30.1211 18.8583C27.4458 32.6061 27.2999 33.4196 29.6909 47.2234L30.6643 52.8435C30.9645 54.5764 33.0054 55.269 34.2385 54.0564L45.9887 55.8352C47.1158 54.7268 48.8842 54.7268 50.0113 55.8352L61.7615 67.3898C62.9946 68.6023 65.0355 67.9097 65.3357 66.1768L66.3091 60.5568C68.7001 46.7529 68.5542 32.6061 65.8789 18.8583C65.2949 15.8574 62.9755 13.543 60.063 13.0376Z"
    path="M44.063 13.0376C36.091 11.6541 27.9091 11.6541 19.937 13.0376C17.0245 13.543 14.7051 15.8574 14.1211 18.8583C11.4458 32.6061 11.2999 46.7529 13.6909 60.5568L14.6643 66.1768C14.9645 67.9097 17.0054 68.6023 18.2385 67.3898L29.9887 55.8352C31.1158 54.7268 32.8842 54.7268 34.0113 55.8352L45.7615 67.3898C46.9946 68.6023 49.0355 67.9097 49.3357 66.1768L50.3091 60.5568C52.7001 46.7529 52.5542 32.6061 49.8789 18.8583C49.2949 15.8574 46.9755 13.543 44.063 13.0376Z"
  />
);

export default SavedIcon;
