/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import MUITooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import QuestionMark from '@icon/QuestionMark';
import { useState } from 'react';
import { breakpoints } from '@styles/index';

const Question = styled(QuestionMark)`
  background-color: var(--color-i-black);
  border-radius: 50%;
  padding: 2px;
`;

const StyledTooltip = styled((props) => (
  <MUITooltip
    classes={{ popper: props.className, tooltip: 'tooltip' }}
    {...props}
  />
))`
  &.MuiTooltip-popper {
    pointer-events: initial;
  }
  & {
    .tooltip {
      background-color: ${({ backgroundColor }) => backgroundColor};
      max-width: ${({ maxWidth }) => maxWidth};
      border-radius: ${({ borderRadius }) => borderRadius};
      padding: ${({ padding }) => padding};
      .MuiTooltip-arrow::before {
        color: ${({ backgroundColor }) => backgroundColor};
      }
      @media only screen and (max-width: ${breakpoints.mobile}) {
        max-width: 100%;
        width: 100%;
        border-radius: 8px;
        padding: ${({ padding }) => padding};
        margin: 0;
      }
    }
    .arrow {
      color: var(--color-i-black);
      left: ${({ left }) => left};
    }
    .popper {
      pointer-events: initial;
    }
    .tooltipPlacementBottom {
      margin-top: 6px;
      margin-right: ${({ marginRight }) => marginRight};
    }
  }
`;

const Tooltip = ({
  children,
  arrow,
  title,
  maxWidth,
  marginRight,
  backgroundColor,
  open,
  placement,
  borderRadius,
  padding,
  left,
  popperProps,
  className,
  enterDelay
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(open);
  return (
    <>
      <StyledTooltip
        arrow={arrow}
        open={open ?? tooltipOpen}
        title={title}
        maxWidth={maxWidth}
        marginRight={marginRight}
        backgroundColor={backgroundColor}
        onClose={() => setTooltipOpen(false)}
        onOpen={() => setTooltipOpen(true)}
        placement={placement}
        className={className}
        borderRadius={borderRadius}
        left={left}
        padding={padding}
        PopperProps={popperProps}
        enterDelay={enterDelay}
      >
        {children || (
          <div>
            <Question size="12px" color="var(--color-i-white)" />
          </div>
        )}
      </StyledTooltip>
    </>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  arrow: PropTypes.bool,
  title: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  marginRight: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  placement: PropTypes.string,
  open: PropTypes.bool,
  borderRadius: PropTypes.string,
  padding: PropTypes.string,
  left: PropTypes.string,
  popperProps: PropTypes.objectOf(PropTypes.any),
  enterDelay: PropTypes.number
};

Tooltip.defaultProps = {
  arrow: true,
  maxWidth: '300px',
  marginRight: '0px',
  backgroundColor: 'var(--color-i-black)',
  className: '',
  placement: 'bottom',
  open: null,
  borderRadius: null,
  padding: null,
  left: null,
  popperProps: {},
  enterDelay: 100
};

export default Tooltip;
