import create from 'zustand/vanilla';
import createHook from 'zustand';
import { readCookie } from '@utils/cookies';
import { localStorageAvailable } from '@utils/localStorage';

const getLocalStorageItem = (key) => {
  if (localStorageAvailable()) {
    return localStorage.getItem(key);
  }
};

export const playerReferralDialogStore = create((set) => ({
  showReferralDialog: false,
  setShowReferralDialog: (classUID) =>
    set(() => {
      const showDialog =
        !readCookie('referral-player') ||
        Math.round(
          (new Date() - new Date(Number(readCookie('referral-player')))) /
            (1000 * 3600 * 24)
        ) > 7;

      return {
        showReferralDialog: classUID
          ? getLocalStorageItem(`liveSessions[${classUID.current}]`) > 1800 &&
            showDialog
          : null
      };
    })
}));

const usePlayerReferralDialogStore = createHook(playerReferralDialogStore);

export default usePlayerReferralDialogStore;
