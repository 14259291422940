import styled from '@emotion/styled';

const Container = styled.div`
  min-height: 100vh;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'var(--color-base-0)'};
  min-width: 100%;
  position: relative;
`;

export default Container;
