export const getIsCatalogueFeatureAvailable = (topology) =>
  topology.isCatalogueFeatureAvailable || false;

export const getIsPlusAvailable = (topology) =>
  topology.isPlusAvailable || false;

export const getTopologySlug = (topology) =>
  topology.goalSlug || topology.slug || '-';

export const getIsSyllabusV1Enabled = (topology) =>
  topology.isSyllabusV1Enabled || false;

export const getIsNoTestGoal = (topology) => topology.isNoTestGoal;

export const getIsPracticeAvailable = (topology) =>
  topology.isPracticeAvailable || false;

export const getIsRecordedGoal = (topology) => topology.isRecordedGoal || false;
