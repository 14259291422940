import Icon from './NavIcon';

const TestsIcon = (props) => (
  <Icon
    {...props}
    opacity=".3"
    cx="40"
    cy="40"
    r="28"
    path="M74 28.502C74 26.2002 72.0323 24.3342 69.605 24.3342H52.0251C49.5978 24.3342 47.6301 26.2002 47.6301 28.502C47.6301 30.8038 49.5978 32.6698 52.0251 32.6698H69.605C72.0323 32.6698 74 30.8038 74 28.502Z M60.8156 41.0069C63.2429 41.0069 65.2106 39.1409 65.2106 36.8391V20.1678C65.2106 17.866 63.2429 16 60.8156 16C58.3884 16 56.4207 17.866 56.4207 20.1678L56.4207 36.8391C56.4207 39.1409 58.3884 41.0069 60.8156 41.0069Z M6.30906 58.3847C5.18247 61.078 7.27506 64 10.3304 64H12.1208C13.9423 64 15.5686 62.9181 16.1886 61.2939L18.8113 54.4232H39.5267L42.214 61.3195C42.8417 62.9304 44.461 64 46.272 64H48.0749C51.1326 64 53.2253 61.0737 52.0941 58.3798L35.4332 18.701C34.7779 17.1403 33.1854 16.1161 31.414 16.1161H26.9281C25.1546 16.1161 23.5608 17.1426 22.9068 18.706L6.30906 58.3847ZM21.8577 46.3302L29.0667 27.6491C29.0824 27.6084 29.1233 27.5813 29.169 27.5813C29.2147 27.5813 29.2556 27.6084 29.2713 27.6491L36.4803 46.3302H21.8577Z"
  />
);

export default TestsIcon;
