import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Spacings } from '@styles/index';
import Tooltip from '@base/Tooltip/Tooltip';
import Typography from 'aquilla/core/atoms/Typography';
import StoreIcon from './StoreIcon';
import { Item } from './NavBarItems';
import plusNavBarItems from './plusNavBarItems';
import { goalHref } from './commonUtils';

const StyStoreIcon = styled(StoreIcon)`
  margin-right: ${Spacings.SPACING_3B};
`;

const StyledItem = styled(Item)`
  margin-bottom: 0;
`;

const TooltipTitle = styled(Typography)`
  color: var(--color-base-1);
  text-align: center;
`;

const StyTooltip = styled(Tooltip)`
  & > .MuiTooltip-tooltip > .MuiTooltip-arrow {
    width: 17px;
    height: ${Spacings.SPACING_6B};
    margin-top: ${Spacings.SPACING_1B};
    margin-left: -1.71em;
    top: ${Spacings.SPACING_6B};
  }
`;

const ItemText = styled.div`
  padding-right: ${Spacings.SPACING_3B};
`;

const itemHoverColor = 'var(--color-i-blue)';

const StoreItem = ({ handleClick, isSelected, goalAs, showStoreTooltip }) => {
  const storeHref = `${goalHref}/store`;
  const as = `${goalAs}/store`;
  return (
    <StyledItem
      $selected={isSelected}
      data-id={plusNavBarItems.STORE}
      onClick={handleClick(plusNavBarItems.STORE, storeHref, as)}
      hovColor={itemHoverColor}
      href={storeHref}
      as={as}
      prefetch
    >
      <StyStoreIcon
        size="20px"
        color={isSelected ? itemHoverColor : 'var(--color-text-secondary)'}
        className="sideNavIcon"
      />
      <StyTooltip
        padding={Spacings.SPACING_4B}
        maxWidth="220px"
        backgroundColor="var(--color-i-blue)"
        title={
          <TooltipTitle variant="h5">
            Explore Store for all your learning needs
          </TooltipTitle>
        }
        open={showStoreTooltip}
        borderRadius={Spacings.SPACING_2B}
        placement="right"
        $showStoreTooltip={showStoreTooltip}
      >
        <ItemText>Store</ItemText>
      </StyTooltip>
    </StyledItem>
  );
};

StoreItem.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  goalAs: PropTypes.string.isRequired
};

export default StoreItem;
