import { localStorageAvailable } from '@utils/localStorage';

export const getGoalAs = (goalUID, goalSlug) => `/goal/${goalSlug}/${goalUID}`;

export const goalHref = '/goal/[goalSlug]/[goalUID]';

export const ICON_IMG_PATH = {
  normal: {
    PRACTICE: 'plusNavBar/practice.svg',
    SAVED: 'plusNavBar/saved.svg',
    PLANNER: 'plusNavBar/planner.svg',
    HOME: 'plusNavBar/home.svg',
    BROWSE: 'plusNavBar/browse.svg',
    SYLLABUS: 'plusNavBar/syllabus.svg',
    TEST_SERIES: 'plusNavBar/testSeries.svg',
    DOUBTS_AND_SOLUTIONS: 'plusNavBar/doubtsAndSolutions2.svg',
    ENROLLMENTS: 'plusNavBar/enrollments.svg',
    FREE_CLASSES_AND_TESTS: 'plusNavBar/freeLiveClasses.svg',
    OTER_COURSES: 'plusNavBar/otherCourses.svg'
  },
  hover: {
    PRACTICE: 'plusNavBar/hovPractice.svg',
    SAVED: 'plusNavBar/hovSaved.svg',
    PLANNER: 'plusNavBar/hovPlanner.svg',
    HOME: 'plusNavBar/hovHome.svg',
    BROWSE: 'plusNavBar/hovBrowse.svg',
    SYLLABUS: 'plusNavBar/hovSyllabus.svg',
    TEST_SERIES: 'plusNavBar/hovTestSeries.svg',
    DOUBTS_AND_SOLUTIONS: 'plusNavBar/hovDoubtsAndSolutions2.svg',
    ENROLLMENTS: 'plusNavBar/hovEnrollments.svg',
    FREE_CLASSES_AND_TESTS: 'plusNavBar/hovFreeLiveClasses.svg',
    OTER_COURSES: 'plusNavBar/hovOtherCourses.svg'
  }
};

const TEST_REVAMP_STORAGE_KEY = 'hide_blue_dot_for_test_revamp';

export const setStorageKeyForTestRevamp = () => {
  if (localStorageAvailable()) {
    localStorage.setItem(TEST_REVAMP_STORAGE_KEY, true);
  }
};

export const getIsBlueDotshownForTestRevamp = (isTestRevampGoal = false) => {
  if (isTestRevampGoal && localStorageAvailable()) {
    const key = localStorage.getItem(TEST_REVAMP_STORAGE_KEY);
    if (key) {
      return false;
    }
    return true;
  }
  return false;
};
