import styled from '@emotion/styled';

const Content = styled.div`
  display: grid;
  grid-column: 1 / 25;
  grid-template-columns: repeat(24, 32px);
  grid-column-gap: 16px;
  grid-auto-rows: max-content;
  grid-row-gap: 16px;
  @media (max-width: 70.95em) {
    grid-template-columns: repeat(24, 1fr);
    grid-column-gap: 8px;
  }
`;

export default Content;
