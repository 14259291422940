import { sideNavFlags } from '@constants/index';

export const PREFERENCES_STEP_INFO = {
  SETUP_DELIVERY: 0,
  DELIVERY_ADDRESS: 1,
  NOTES_PREFERENCES: 2,
  CONFIRM_DELIVERY_PREFERENCES: 3,
  PREPARING_PACKAGE: 4
};

export const addressOnboardingStepsMap = {
  ADDRESS: 1,
  ADDRESS_NOTES: 2
};

export const IndiaMap = {
  code: 'IN',
  name: 'India',
  flagIconUrl: 'https://static.uacdn.net/thumbnail/country-flag-icons/in.png',
  phoneCode: '91'
};

export const addressFormErrorMap = {
  ENTER_FULL_NAME: 'Enter full name',
  ENTER_MOBILE_NUMBER: 'Enter mobile number',
  ENTER_PINCODE: 'Enter pincode',
  ENTER_ADDRESS: 'Enter an address',
  ENTER_CITY: 'Enter a city name',
  ENTER_STATE: 'Enter a state name',
  INVALID_PINCODE: 'Enter a valid pincode for India',
  INSUFFICIENT_DIGITS_IN_MOBILE_NUMBER:
    'Entered mobile number has insufficient digits',
  SPECIAL_CHARACTERS_NOT_ALLOWED: 'Special characters are not allowed'
};

export const footerErrorMap = {
  INVALID_DELIVERY_ADDRESS: 'Add a valid delivery address to continue',
  INVALID_NOTES_PREFERENCES: 'Select books preferences to continue',
  DEFAULT_ERROR: 'Something went wrong. Please try again.'
};

export const notesPrefTypes = {
  PHYSICAL_NOTES_BOOKS: 1,
  PHYSICAL_NOTES_LANGUAGE: 2,
  STREAM: 3
};

export const headerScrollOffset = 90;

export const preferenceDrawerTitle = {
  [PREFERENCES_STEP_INFO.DELIVERY_ADDRESS]: 'Add delivery address',
  [PREFERENCES_STEP_INFO.NOTES_PREFERENCES]: 'Select books preferences',
  [PREFERENCES_STEP_INFO.CONFIRM_DELIVERY_PREFERENCES]:
    'Confirm your delivery preferences',
  [PREFERENCES_STEP_INFO.PREPARING_PACKAGE]: 'We’re preparing your package'
};

export const confirmationTitle = {
  [addressOnboardingStepsMap.ADDRESS]: 'Confirm your delivery address',
  [addressOnboardingStepsMap.ADDRESS_NOTES]: 'Confirm your delivery preferences'
};

export const isPrintedNotesEnabled = (featureArray = []) => {
  const ele = featureArray.find(
    (feature) => feature.type === sideNavFlags.PRINTED_NOTES
  );
  return !!ele;
};

export const printedNotesPageName = 'Unacademy books';
