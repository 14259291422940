import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Spacings } from '@styles/index';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { getMe } from '@api/selectors/auth';
import useTopology from '@api/hooks/topology/useTopology';
import { useSelectedGoalUID } from '@api/hooks/topology/useSelectedGoal';
import {
  getIsSubscribedToPlus,
  getIsSubscribedToLite
} from '@api/selectors/subscription';
import SideNavEnrollments from './EnrollmentsIcon';
import SideNavSaved from './SavedIcon';
import { Item } from './NavBarItems';
import { StyH7 } from './CommonComps';
import { goalHref } from './commonUtils';
import plusNavBarItems from './plusNavBarItems';

const StySideNavEnrollments = styled(SideNavEnrollments)`
  margin-right: ${Spacings.SPACING_3B};
`;
const StySideNavSaved = styled(SideNavSaved)`
  margin-right: ${Spacings.SPACING_3B};
`;

const getMeSectionTouchPoints = (
  handleClick,
  goalAs,
  hasPlusSubscription,
  hasLiteSubscription,
  selectedNav
) => [
  {
    label: 'Enrollments',
    dataId: plusNavBarItems.ENROLLMENTS,
    isSelected: selectedNav === plusNavBarItems.ENROLLMENTS,
    href: `${goalHref}/enrolled`,
    as: `${goalAs}/enrolled`,
    itemHandleClick: handleClick(
      plusNavBarItems.ENROLLMENTS,
      `${goalHref}/enrolled`,
      `${goalAs}/enrolled`
    ),
    key: plusNavBarItems.ENROLLMENTS,
    isShown: hasPlusSubscription || hasLiteSubscription,
    hovColor: 'var(--color-i-turquoise-4)',
    icon: StySideNavEnrollments
  },
  {
    label: 'Saved',
    dataId: plusNavBarItems.SAVED,
    isSelected: selectedNav === plusNavBarItems.SAVED,
    href: `${goalHref}/saved`,
    as: `${goalAs}/saved`,
    itemHandleClick: handleClick(
      plusNavBarItems.SAVED,
      `${goalHref}/saved`,
      `${goalAs}/saved`
    ),
    key: plusNavBarItems.SAVED,
    isShown: hasPlusSubscription || hasLiteSubscription,
    hovColor: 'var(--color-i-orange-11)',
    icon: StySideNavSaved
  }
];

const MeSection = ({ handleClick, selectedNav, goalAs }) => {
  const router = useRouter();
  const { goalUID: selectedGoalFromURL } = router.query;
  const selectedGoalFromStore = useSelectedGoalUID();
  const selectedGoal = selectedGoalFromURL || selectedGoalFromStore;
  const me = useMyInfo(getMe);
  const { topology: goalInfo } = useTopology(selectedGoal);
  const hasPlusSubscription = getIsSubscribedToPlus(
    me,
    goalInfo.uid || selectedGoal
  );
  const hasLiteSubscription = getIsSubscribedToLite(
    me,
    goalInfo.uid || selectedGoal
  );

  const tabsMap = getMeSectionTouchPoints(
    handleClick,
    goalAs,
    hasPlusSubscription,
    hasLiteSubscription,
    selectedNav
  );
  return (
    <>
      <StyH7>ME</StyH7>
      {tabsMap
        .filter((item) => item.isShown)
        .map((item) => {
          return (
            <Item
              key={item.key}
              $selected={item.isSelected}
              data-id={item.dataId}
              onClick={item.itemHandleClick}
              className={item.key === plusNavBarItems.SAVED ? 'lastItem' : null}
              hovColor={item.hovColor}
              href={item.href}
              as={item.as}
              prefetch
            >
              <item.icon
                size="20px"
                color={
                  item.isSelected
                    ? item.hovColor
                    : 'var(--color-text-secondary)'
                }
                className="sideNavIcon"
              />
              {item.label}
            </Item>
          );
        })}
    </>
  );
};

export default MeSection;
MeSection.propTypes = {
  handleClick: PropTypes.func.isRequired,
  selectedNav: PropTypes.string
};
MeSection.defaultProps = {
  selectedNav: ''
};
