import Icon from '@layout/NavBar/PlusNavBar/NavIcon';

const PrintedNotesIcon = (props) => {
  return (
    <Icon
      {...props}
      viewBox="0 0 20 20"
      pathFillRule="evenodd"
      pathClipRule="evenodd"
      opacity="0.3"
      gpath="M3 17.0381C3 17.5904 3.44772 18.0381 4 18.0381H15.5V2.03809H4C3.44772 2.03809 3 2.4858 3 3.03809V17.0381Z"
      path="M16 2H5.5V18H16C16.5523 18 17 17.5224 17 16.9333V3.06667C17 2.47756 16.5523 2 16 2ZM8 12.6667C7.72386 12.6667 7.5 12.9054 7.5 13.2V15.3333C7.5 15.6279 7.72386 15.8667 8 15.8667H14.5C14.7761 15.8667 15 15.6279 15 15.3333V13.2C15 12.9054 14.7761 12.6667 14.5 12.6667H8Z"
    />
  );
};

export default PrintedNotesIcon;
