import { getIsLoggedIn } from '@api/selectors/auth';
import useFetch from '@hooks/useFetch';
import useMyInfo from './auth/useMyInfo';

const useSidenavFlags = (goalUID) => {
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const { data, ...rest } = useFetch(
    isLoggedIn && goalUID
      ? `v1/user/me/menu_features_props/?goal_uid=${goalUID}`
      : null
  );
  return { sidenavFlags: data, ...rest };
};

export default useSidenavFlags;
