import Icon from './NavIcon';

const LiveMentoringIcon = (props) => (
  <Icon
    {...props}
    opacity=".3"
    pathFillRule="evenodd"
    pathClipRule="evenodd"
    gpath="M24.1176 37.5064C19.2445 37.5064 15.2941 33.556 15.2941 28.6829C15.2941 23.8098 19.2445 19.8594 24.1176 19.8594C28.9907 19.8594 32.9412 23.8098 32.9412 28.6829C32.9412 33.556 28.9907 37.5064 24.1176 37.5064Z M34.5628 67.5064C20.9971 67.5064 10 56.5093 10 42.9436C10 41.89 10.8541 41.0358 11.9078 41.0358H32.9412C40.7381 41.0358 47.0588 47.3565 47.0588 55.1535V67.5064H34.5628Z"
    path="M47.0593 37.5057C40.237 37.5057 34.7064 31.9751 34.7064 25.1527C34.7064 18.3304 40.237 12.7998 47.0593 12.7998C53.8817 12.7998 59.4123 18.3304 59.4123 25.1527C59.4123 31.9751 53.8817 37.5057 47.0593 37.5057Z M47.0593 67.5056C34.3893 67.5056 24.1182 57.2345 24.1182 44.5645V42.9429C24.1182 41.8892 24.9723 41.0351 26.026 41.0351H68.0927C69.1463 41.0351 70.0005 41.8892 70.0005 42.9429V44.5645C70.0005 57.2345 59.7294 67.5056 47.0593 67.5056Z"
  />
);

export default LiveMentoringIcon;
