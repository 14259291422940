export const ADDRESS_COLLECTION_SET_UP_STARTED =
  'Address Collection - Set Up Started';
export const ADDRESS_COLLECTION_ADDRESS_FILL_CONTINUE_CLICKED =
  'Address Collection - Address Fill Continue Clicked';
export const ADDRESS_COLLECTION_ADDRESS_FILL_CONTINUE_FAILED =
  'Address Collection - Address Fill Continue Failed';
export const ADDRESS_COLLECTION_PREFERENCE_NEXT_CLICKED =
  'Address Collection - Preference Next Clicked';
export const ADDRESS_COLLECTION_ADDRESS_CONFIRM_CLICKED =
  'Address Collection - Address Confirm Clicked';
export const ADDRESS_COLLECTION_ADDRESS_BACK_CLICKED =
  'Address Collection - Address Back Clicked';
export const ADDRESS_COLLECTION_ADDRESS_CONFIRMED_NEXT_CLICKED =
  'Address Collection - Address Confirmed Next Clicked';
export const ADDRESS_COLLECTION_DO_IT_LATER_CLICKED =
  'Address Collection - Do it Later Clicked';
export const ADDRESS_COLLECTION_UNACADEMY_BOOKS_ADD_PREFERENCES_STARTED =
  'Address Collection - Unacademy Books Add Preferences Started';

export const UNACADEMY_BOOKS_SECTION_CLICKED =
  'Unacademy Books - Section Clicked';
export const UNACADEMY_BOOKS_PAGE_VIEWED = 'Unacademy Books - Page Viewed';
export const UNACADEMY_BOOKS_TRACK_BUTTON_CLICKED =
  'Unacademy Books - Track Button Clicked';
export const UNACADEMY_BOOKS_TRACKING_FAILED =
  'Unacademy Notes - Tracking Failed';
