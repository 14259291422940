import Icon from './NavIcon';

const FreeLiveClasses = (props) => (
  <Icon
    {...props}
    opacity=".3"
    pathFillRule="evenodd"
    pathClipRule="evenodd"
    gpath="M60 34C60 29.5817 56.4183 26 52 26H16C11.5817 26 8 29.5817 8 34V58C8 62.4183 11.5817 66 16 66H52C56.4183 66 60 62.4183 60 58V34Z"
    path="M64 14C68.4183 14 72 17.5817 72 22V46C72 50.4183 68.4183 54 64 54H28C23.5817 54 20 50.4183 20 46V22C20 17.5817 23.5817 14 28 14H64ZM53.1736 32.3346C54.2755 33.1776 54.2755 34.8224 53.1736 35.6654C49.8436 38.2131 46.1251 40.2212 42.1574 41.6146L41.4326 41.8691C40.0459 42.3561 38.5811 41.4284 38.3934 40.013C37.8689 36.0584 37.8689 31.9416 38.3934 27.987C38.5811 26.5716 40.0459 25.6439 41.4326 26.1309L42.1574 26.3854C46.1251 27.7788 49.8436 29.7869 53.1736 32.3346Z"
  />
);

export default FreeLiveClasses;
