import styled from '@emotion/styled';
import H7 from '@base/H7/H7';
import { Spacings, Fonts } from '@styles/index';

export const StyH7 = styled(H7)`
  margin-top: ${({ $isLite }) => ($isLite ? 0 : `${Spacings.SPACING_8B}`)};
  padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_4B};
  color: var(--color-text-secondary);
  font-weight: ${Fonts.BOLD};
`;

export const NumberDiv = styled.div`
  background-color: var(--color-divider);
  border-radius: ${Spacings.SPACING_1B};
  padding: ${Spacings.SPACING_2} ${Spacings.SPACING_6};
  font-size: ${Fonts.EXTRA_SMALL} !important;
  font-weight: ${Fonts.BOLD} !important;
  line-height: ${Fonts.PARA_LINE_HEIGHT} !important;
  margin-left: auto;
  &:hover {
    background-color: var(--color-divider) !important;
  }
`;
