import Icon from './NavIcon';

const SideNavEnrollments = (props) => (
  <Icon
    {...props}
    opacity=".3"
    gpath="M63.9638 41.3301C63.9878 40.8898 64 40.4463 64 40C64 26.7452 53.2548 16 40 16C26.7452 16 16 26.7452 16 40C16 53.2548 26.7452 64 40 64C42.5066 64 44.9235 63.6157 47.1949 62.9029C49.7627 66.0157 53.6496 68 58 68C65.732 68 72 61.732 72 54C72 48.401 68.7132 43.5696 63.9638 41.3301Z"
    path="M43.9014 40.0007C43.9014 42.0253 42.1382 43.8912 40.0109 43.8912C37.8836 43.8912 36.1204 42.0253 36.1204 40.0007C36.1204 37.9761 37.8836 36.1102 40.0109 36.1102C42.1382 36.1102 43.9014 37.9761 43.9014 40.0007Z"
    pathFillRule2="evenodd"
    pathClipRule2="evenodd"
    path2="M40 68C55.464 68 68 55.464 68 40C68 24.536 55.464 12 40 12C24.536 12 12 24.536 12 40C12 55.464 24.536 68 40 68ZM23.4539 26.0774C22.4701 24.3266 24.5408 22.4633 26.3223 23.4964L45.3154 34.5096C45.6284 34.6911 45.8847 34.9491 46.0576 35.2568L56.5461 53.9226C57.5299 55.6734 55.4592 57.5367 53.6777 56.5036L34.6846 45.4904C34.3716 45.3089 34.1153 45.0509 33.9424 44.7432L23.4539 26.0774Z"
  />
);

export default SideNavEnrollments;
