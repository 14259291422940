import Icon from '@layout/NavBar/PlusNavBar/NavIcon';

const NavNotes = (props) => {
  return (
    <Icon
      {...props}
      viewBox="0 0 20 20"
      pathFillRule="evenodd"
      pathClipRule="evenodd"
      opacity="0.3"
      gpath="M5.5 4C5.5 2.89543 4.60457 2 3.5 2V2C2.39543 2 1.5 2.89543 1.5 4V16C1.5 17.1046 2.39543 18 3.5 18H5.5V4Z"
      path=" M5.5 4C5.5 2.89543 6.39543 2 7.5 2H16.5C17.6046 2 18.5 2.89543 18.5 4V16C18.5 17.1046 17.6046 18 16.5 18H5.5V4Z M16 7.4C16 7.73137 15.7314 8 15.4 8H8.6C8.26863 8 8 7.73137 8 7.4V7.4C8 7.06863 8.26863 6.8 8.6 6.8H15.4C15.7314 6.8 16 7.06863 16 7.4V7.4Z M16 9.9C16 10.2314 15.7314 10.5 15.4 10.5H8.6C8.26863 10.5 8 10.2314 8 9.9V9.9C8 9.56863 8.26863 9.3 8.6 9.3H15.4C15.7314 9.3 16 9.56863 16 9.9V9.9Z M10.5 12.4C10.5 12.7314 10.2314 13 9.9 13H8.7C8.36863 13 8.1 12.7314 8.1 12.4V12.4C8.1 12.0686 8.36863 11.8 8.7 11.8H9.9C10.2314 11.8 10.5 12.0686 10.5 12.4V12.4Z"
    />
  );
};

export default NavNotes;
