import styled from '@emotion/styled';
import { Spacings } from '@styles/index';

const AppContainer = styled.div`
  width: ${Spacings.APP_CONTAINER_WIDTH};
  margin: auto;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  min-height: ${({ $isFooterHidden }) => ` calc(
    100vh -
      ${
        Spacings.SPACING_HEADER +
        Spacings.SPACING_HEADER_MARGIN +
        ($isFooterHidden
          ? 0
          : Spacings.SPACING_FOOTER + Spacings.SPACING_FOOTER_MARGIN + 12)
      }px
  )`};
  @media (max-width: 70.95em) {
    width: ${({ $tabletWidth }) => $tabletWidth || '1136px'};
    grid-template-columns: repeat(24, 1fr);
  }
`;

export default AppContainer;
