import { breakpoints, Fonts, mediaQuery, Spacings } from '@styles/index';
import styled from '@emotion/styled';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import Link from '@base/Link/Link';

export const MainItem = styled.li`
  padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_4B};

  > a,
  > span,
  > div {
    font-weight: ${Fonts.BOLD};
    font-size: ${Fonts.H4};
    line-height: ${Fonts.HEADER_LINE_HEIGHT};
    margin: ${Spacings.SPACING_0B};
    transition: color 400ms ease-in-out;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    color: ${({ selected }) =>
      selected ? 'var(--color-i-green)' : 'var(--color-text-primary)'};
    :hover {
      color: ${({ disabled }) =>
        disabled ? 'inherit' : 'var(--color-i-green)'};
    }
    @media only screen and (max-width: ${breakpoints.tablet}) {
      font-size: ${Spacings.SPACING_4B};
      line-height: 100%;
    }
  }
`;

export const Item = styled(Link)`
  margin: ${Spacings.SPACING_0B};
  margin-bottom: ${Spacings.SPACING_2B};
  padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_4B};
  display: flex;
  font-weight: ${Fonts.SEMIBOLD};
  line-height: ${Fonts.HEADER_LINE_HEIGHT};
  font-size: ${Fonts.LARGE};
  position: relative;
  &.lastItem {
    margin-bottom: ${Spacings.SPACING_10B};
  }

  align-items: center;
  .sideNavIcon {
    fill: ${({ $selected, hovColor }) => ($selected ? hovColor : null)};
    * {
      fill: ${({ $selected, hovColor }) => ($selected ? hovColor : null)};
      stroke: ${({ $selected, hovColor }) => ($selected ? hovColor : null)};
    }
  }
  &:hover {
    background-color: var(--color-base-1);
    cursor: pointer;
    box-shadow: ${boxShadowGenerator({
      yOffset: Spacings.SPACING_1B,
      blur: Spacings.SPACING_4B
    })};
    border-radius: ${Spacings.SPACING_2B};
  }
  &:hover .sideNavIcon {
    color: ${({ hovColor }) => hovColor};
    fill: ${({ hovColor }) => hovColor};
    * {
      fill: ${({ hovColor }) => hovColor};
      stroke: ${({ hovColor }) => hovColor};
    }
  }

  > a,
  > span,
  > div {
    transition: color 400ms ease-in-out;
    cursor: pointer;
    color: var(--color-text-primary);
    :hover {
      background-color: var(--color-base-1);
    }
    ${mediaQuery.uptoMobile} {
      font-size: ${Spacings.SPACING_3B};
    }
  }
`;
