import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Spacings } from '@styles/index';
import useGoalEventProps from '@hooks/useGoalEventProps';
import { getMe, getIsLoggedIn } from '@api/selectors/auth';
import useWindowSize from '@hooks/useWindowSize';
import {
  PRACTICE_SECTION_CLICKED,
  SYLLABUS_TAB_CLICKED,
  AAD_TAB_CLICKED,
  SAVE_SAVE_VIEWED,
  VIDEO_LESSONS_TAB_CLICKED
} from '@constants/segment';
import { segment } from '@actions/analytics';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import cookies from '@utils/cookies';
import usePlatformInfo from '@hooks/usePlatformInfo';
import useTopology from '@api/hooks/topology/useTopology';
import {
  getIsCatalogueFeatureAvailable,
  getIsPlusAvailable,
  getIsRecordedGoal,
  getIsSyllabusV1Enabled,
  getTopologySlug
} from '@api/selectors/topology';
import {
  getIsSubscribedToPlus,
  getIsSubscribedToLite,
  getIsSubscribedToOffline,
  getMeCenterSubscription
} from '@api/selectors/subscription';
import { getIsGTPGoal, getIsTestRevampGoal } from '@api/selectors/constants';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { useSelectedGoalUID } from '@api/hooks/topology/useSelectedGoal';
import useConstants from '@api/hooks/useConstants';
import { NOTES_SECTION_CLICKED } from '@cont/EpubNotes/utils';
import useSidenavStore, { setSelectedNav } from '@stores/sidenav';
import { getSelectedNav } from '@stores/selectors/sidenav';
import { localStorageAvailable } from '@utils/localStorage';
import { UNACADEMY_BOOKS_SECTION_CLICKED } from '@cont/AddressCollection/events';
import useUserProfileInfo from '@api/hooks/goal/useUserProfileInfo';
import plusNavBarItems from './plusNavBarItems';
import SelfStudy from './SelfStudy';
import MeSection from './MeSection';
import PlannerItem from './PlannerItem';
import StoreItem from './StoreItem';
import Home from './Home';
import { getGoalAs, setStorageKeyForTestRevamp } from './commonUtils';
import PlatformNavItems from './PlatformNavItems';

const DEFAULT_ITEM_HEIGHT = 37;

const Selected = styled.div`
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  height: ${DEFAULT_ITEM_HEIGHT}px;
  width: calc(100% - 40px);
  position: absolute;
  background-color: var(--color-base-1);
  top: 0px;
  transform-origin: top;
  transform: ${({ $posY, $scaleY }) =>
    `translateY(${$posY}px) scaleY(${$scaleY})`};
  transition: transform 400ms ease-in, visibility 400ms;
  border-radius: ${Spacings.SPACING_2B};
  box-shadow: ${boxShadowGenerator({
    yOffset: Spacings.SPACING_4B,
    blur: Spacings.SPACING_8B
  })};
`;

const navKeyEventNameMap = {
  [plusNavBarItems.PLUS_HOME]: 'Home - Section Viewed',
  [plusNavBarItems.SCHEDULE]: 'Planner - Section Viewed',
  [plusNavBarItems.FREE_CLASSES_AND_TESTS]:
    'Free Live Classes - Section Viewed',
  [plusNavBarItems.FREE_PLATFORM]: 'Other Courses - Section Viewed',
  [plusNavBarItems.PRACTICE]: PRACTICE_SECTION_CLICKED,
  [plusNavBarItems.TEST_SERIES]: 'Test Series - Section Clicked',
  [plusNavBarItems.COMPETE]: 'Compete - Section Clicked',
  [plusNavBarItems.ENROLLMENTS]: 'Enrollments - Section Viewed',
  [plusNavBarItems.SYLLABUS]: SYLLABUS_TAB_CLICKED,
  [plusNavBarItems.NOTES]: NOTES_SECTION_CLICKED,
  [plusNavBarItems.SAVED]: SAVE_SAVE_VIEWED,
  [plusNavBarItems.DOUBTS_AND_SOLUTIONS]: AAD_TAB_CLICKED,
  [plusNavBarItems.GET_THE_APP]: 'Get Learning App - Clicked',
  [plusNavBarItems.OTHER_LINKS]: 'Other Links - Clicked',
  [plusNavBarItems.REPORT_AN_ISSUE]: 'Report An Issue - Clicked',
  [plusNavBarItems.BROWSE]: 'Browse - Browse Section Clicked',
  [plusNavBarItems.ENROLLMENTS]: 'Enrollments - Section Viewed',
  [plusNavBarItems.TEST_SERIES]: 'Test - Tests Section Viewed',
  [plusNavBarItems.DOUBTS_AND_SOLUTIONS]: 'Doubts & Solutions - Section Viewed',
  [plusNavBarItems.ONE_TO_ONE_LIVE_MENTORSHIP]: 'Live Mentoring - Menu Clicked',
  [plusNavBarItems.ASK_A_DOUBT]: 'Ask a Doubt - Tab Clicked',
  [plusNavBarItems.VIDEO_LESSONS]: 'Video Lessons - Clicked',
  [plusNavBarItems.PRINTED_NOTES]: UNACADEMY_BOOKS_SECTION_CLICKED
};

const handleStorageKeyForTestRevamp = (section, isTestRevampGoal) => {
  if (
    navKeyEventNameMap[section] ===
      navKeyEventNameMap[plusNavBarItems.TEST_SERIES] &&
    isTestRevampGoal
  ) {
    setStorageKeyForTestRevamp();
  }
};

const sendSegmentEvent = (
  section,
  routeGoalSegmentData,
  isSyllabusV1Enabled,
  isGTP
) => {
  if (
    navKeyEventNameMap[section] ===
    navKeyEventNameMap[plusNavBarItems.DOUBTS_AND_SOLUTIONS]
  ) {
    const isDoubtsAndSolutionVisited = cookies.readCookie(
      'doubtsAndSolutionVisited',
      false
    );
    segment.track(navKeyEventNameMap[plusNavBarItems.ASK_A_DOUBT], {
      ...routeGoalSegmentData,
      dot_type: isDoubtsAndSolutionVisited ? 'null' : 'blue',
      last_primary_source_section: 'Side Navigation Bar'
    });
    if (!isDoubtsAndSolutionVisited) {
      cookies.setCookie('doubtsAndSolutionVisited', true, 180);
    }
  }

  if (
    navKeyEventNameMap[section] === navKeyEventNameMap[plusNavBarItems.NOTES]
  ) {
    const isNotesVisited = cookies.readCookie('notesVisited', false);
    segment.track(navKeyEventNameMap[plusNavBarItems.NOTES], {
      ...routeGoalSegmentData,
      dot_type: isNotesVisited ? 'null' : 'blue',
      last_primary_source_section: 'Side Navigation Bar'
    });
    if (!isNotesVisited) {
      cookies.setCookie('notesVisited', true, 100);
    }
  }

  if (
    navKeyEventNameMap[section] ===
    navKeyEventNameMap[plusNavBarItems.PRINTED_NOTES]
  ) {
    if (localStorageAvailable()) {
      localStorage.setItem('isPrintedNotesVisited', true);
    }
  }

  const isSyllabusPage =
    navKeyEventNameMap[section] ===
    navKeyEventNameMap[plusNavBarItems.SYLLABUS];

  if (isSyllabusV1Enabled && isSyllabusPage) {
    const isSyllabusVisited = cookies.readCookie('syllabusVisited', false);
    segment.track(navKeyEventNameMap[plusNavBarItems.SYLLABUS], {
      ...routeGoalSegmentData,
      dot_type: isSyllabusVisited ? 'null' : 'blue',
      last_primary_source_section: 'Side Navigation Bar'
    });
    if (!isSyllabusVisited) {
      cookies.setCookie('syllabusVisited', true, 180);
    }
    return;
  }

  const eventName =
    isSyllabusPage && isGTP
      ? VIDEO_LESSONS_TAB_CLICKED
      : navKeyEventNameMap[section];
  segment.track(eventName, {
    ...routeGoalSegmentData,
    last_primary_source_section: 'Side Navigation Bar'
  });
};

const NavItems = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const PlusNavBarFixed = styled.div`
  position: sticky;
  top: ${Spacings.SPACING_HEADER}px;
  left: 0;
  width: 100%;
  padding: ${Spacings.SPACING_10B} ${Spacings.SPACING_10B} 0 0;
  height: auto;
  max-height: calc(100vh - ${Spacings.SPACING_HEADER + 16}px);
  overflow-y: auto;

  scrollbar-width: ${Spacings.SPACING_2B};
  ::-webkit-scrollbar {
    width: ${Spacings.SPACING_2B};
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    width: ${Spacings.SPACING_2B};
    border-radius: ${Spacings.SPACING_10B};
    background: transparent;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background: var(--color-text-secondary);
    }
  }
`;

const StoreItemWrapper = styled.div`
  width: 100%;
  margin-top: ${Spacings.SPACING_2B};
`;

const PlusNavBar = () => {
  const router = useRouter();
  const { height, width } = useWindowSize();

  const [selectedNavMeta, setSelectedNavMeta] = useState({
    height: DEFAULT_ITEM_HEIGHT,
    scaleY: 1,
    posY: 0
  });
  const [showStoreTooltip, setShowStoreTooltip] = useState(false);

  const { goalUID: routerGoalUID } = router.query;
  const selectedGoalUID = useSelectedGoalUID();
  const goalUID = routerGoalUID || selectedGoalUID;
  const isGTP = useConstants(getIsGTPGoal(goalUID));

  const { topology } = useTopology(goalUID);

  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const me = useMyInfo(getMe);
  const hasPlusSubscription = getIsSubscribedToPlus(goalUID)(me);
  const isSubscribedToLite = getIsSubscribedToLite(goalUID)(me);
  const isOfflineSubscription = getIsSubscribedToOffline(goalUID)(me);
  const hasCentreSubscription = getMeCenterSubscription(me);

  const isCatalogueFeatureAvailable = getIsCatalogueFeatureAvailable(topology);
  const isPlusAvailable = getIsPlusAvailable(topology);
  const goalSlug = getTopologySlug(topology);
  const isSyllabusV1Enabled = getIsSyllabusV1Enabled(topology);
  const isRecordedGoal = getIsRecordedGoal(topology);

  const selectedNav = useSidenavStore(getSelectedNav);
  const routeGoalSegmentData = useGoalEventProps({ goalUID: routerGoalUID });

  const isTestRevampGoal = useConstants(getIsTestRevampGoal(goalUID));

  const { showPlatformNav } = usePlatformInfo();
  const { userProfileInfo } = useUserProfileInfo(goalUID);
  const { showStoreTab } = userProfileInfo || {};

  useEffect(() => {
    const el = document.querySelector(`[data-id="${selectedNav}"]`);
    const selectedElemHeight = el?.offsetHeight;
    const posY = el?.offsetTop + Spacings.SPACING_HEADER_MARGIN;
    if (
      selectedNavMeta.posY === posY &&
      selectedNavMeta.height === selectedElemHeight
    ) {
      return () => {};
    }
    window.posY = posY;
    setSelectedNavMeta({
      height: selectedElemHeight,
      scaleY: selectedElemHeight / DEFAULT_ITEM_HEIGHT,
      posY
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedNav,
    height,
    width,
    isLoggedIn,
    isCatalogueFeatureAvailable,
    isSubscribedToLite
  ]);

  useEffect(() => {
    if (localStorageAvailable()) {
      if (!localStorage.getItem('browseTooltipShown')) {
        setShowStoreTooltip(true);
        localStorage.setItem('browseTooltipShown', 'true');
        const showStoreTooltipTimeout = setTimeout(() => {
          setShowStoreTooltip(false);
        }, 5000);

        return () => {
          clearTimeout(showStoreTooltipTimeout);
        };
      }
    }
  }, []);

  const handleClick = (nav) => () => {
    setSelectedNav(nav);
    sendSegmentEvent(nav, routeGoalSegmentData, isSyllabusV1Enabled, isGTP);

    handleStorageKeyForTestRevamp(nav, isTestRevampGoal);
  };

  const isSubscribedAndPlusAvailable = hasPlusSubscription && isPlusAvailable;

  const showPlannerItem =
    (isSubscribedAndPlusAvailable || isOfflineSubscription) &&
    !isGTP &&
    !isRecordedGoal;

  const goalAs = getGoalAs(goalUID, goalSlug);

  return (
    <PlusNavBarFixed>
      <Selected
        $isVisible={selectedNavMeta.height} // In case of an invalid tab, the height will be undefined
        $scaleY={selectedNavMeta.scaleY}
        $posY={
          // window.posY to ensure that due to layout remounts, the entire navbar doesn't remount post page transitions.
          typeof window !== 'undefined'
            ? window.posY || selectedNavMeta.posY
            : selectedNavMeta.posY
        }
      />
      <NavItems>
        {(!isLoggedIn ||
          !(hasPlusSubscription || isOfflineSubscription) ||
          !isPlusAvailable) &&
          !showPlatformNav &&
          !isSubscribedToLite && (
            <Home
              handleClick={handleClick}
              isSelected={selectedNav === plusNavBarItems.PLUS_HOME}
              goalAs={goalAs}
            />
          )}
        {showPlannerItem && (
          <PlannerItem
            handleClick={handleClick}
            selectedNav={selectedNav}
            goalAs={goalAs}
            isSelected={selectedNav === plusNavBarItems.SCHEDULE}
          />
        )}
        {showStoreTab && (
          <StoreItemWrapper>
            <StoreItem
              handleClick={handleClick}
              selectedNav={selectedNav}
              goalAs={goalAs}
              isSelected={selectedNav === plusNavBarItems.STORE}
              showStoreTooltip={showStoreTooltip}
            />
          </StoreItemWrapper>
        )}

        {showPlatformNav && (
          <PlatformNavItems
            handleClick={handleClick}
            selectedNav={selectedNav}
            goalUID={goalUID}
            goalAs={goalAs}
          />
        )}
        {!showPlatformNav && (
          <SelfStudy
            handleClick={handleClick}
            selectedNav={selectedNav}
            goalUID={goalUID}
            goalAs={goalAs}
            hasPlusSubscription={hasPlusSubscription}
            hasOfflineSubscription={isOfflineSubscription}
            isCatalogueFeatureAvailable={isCatalogueFeatureAvailable}
            hasCentreSubscription={hasCentreSubscription}
          />
        )}
        {!showPlatformNav &&
          (isOfflineSubscription ||
            isSubscribedAndPlusAvailable ||
            isSubscribedToLite) &&
          !isGTP && (
            <MeSection
              handleClick={handleClick}
              selectedNav={selectedNav}
              goalAs={goalAs}
            />
          )}
      </NavItems>
    </PlusNavBarFixed>
  );
};

export default PlusNavBar;
