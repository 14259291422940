import Icon from './NavIcon';

const SyllabusIcon = (props) => (
  <Icon
    {...props}
    pathFillRule="evenodd"
    pathClipRule="evenodd"
    opacity="0.3"
    gpath="M66.4672 25.7249C70.962 28.4541 72.9767 33.966 71.1468 38.9608C69.7559 42.7571 68.0634 47.1252 66.4807 50.538C64.8981 53.9508 62.6572 58.0645 60.6578 61.5786C58.0273 66.2022 52.5183 68.2249 47.5314 66.557C39.6527 63.9218 32.0932 60.4163 24.992 56.1045C20.4966 53.375 18.4831 47.8622 20.3133 42.8668C21.7065 39.0645 23.4003 34.692 24.9775 31.2909C26.5548 27.8898 28.7982 23.7722 30.8007 20.2525C33.4315 15.6283 38.9403 13.6041 43.9279 15.2722C51.8066 17.9074 59.366 21.4132 66.4672 25.7249Z"
    path="M59.1404 21.3897C58.8186 17.0323 55.4774 13.5315 51.1509 12.9219C42.4273 11.6928 33.5736 11.6927 24.8499 12.9218C20.5229 13.5315 17.1821 17.0334 16.8607 21.3913C16.4569 26.8656 16 34.316 16 40.0002C16 45.6843 16.4569 53.1347 16.8607 58.609C17.1821 62.9669 20.5229 66.4689 24.8499 67.0785C33.5736 68.3077 42.4273 68.3076 51.1509 67.0784C55.4774 66.4688 58.8186 62.968 59.1404 58.6106C59.5439 53.1454 60 45.7057 60 40.0002C60 34.2946 59.5439 26.855 59.1404 21.3897ZM47.8332 35.7847C49.0562 34.4087 48.9322 32.3018 47.5562 31.0788C46.1802 29.8559 44.0733 29.9799 42.8503 31.3559L33.9132 41.411L32.1189 39.3923C30.8959 38.0163 28.789 37.8923 27.413 39.1153C26.037 40.3383 25.913 42.4452 27.136 43.8212L31.4217 48.6431C32.0543 49.3547 32.961 49.762 33.9132 49.762C34.8653 49.762 35.7721 49.3547 36.4046 48.6431L47.8332 35.7847Z"
  />
);

export default SyllabusIcon;
