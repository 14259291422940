import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Spacings } from '@styles/index';
import { batchGroupNave } from '@cont/BatchGroup/constants';
import { goalHref } from './commonUtils';
import SideNavSaved from './SavedIcon';
import plusNavBarItems from './plusNavBarItems';
import BrowseIcon from './BrowseIcon';
import HomeIcon from './HomeIcon';
import { Item } from './NavBarItems';

const StyleSideNavHome = styled(HomeIcon)`
  margin-right: ${Spacings.SPACING_3B};
`;
const StySideNavSaved = styled(SideNavSaved)`
  margin-right: ${Spacings.SPACING_3B};
`;
const StyBrowseIcon = styled(BrowseIcon)`
  margin-right: ${Spacings.SPACING_3B};
`;

const IconDot = styled.span`
  width: ${Spacings.SPACING_2B};
  height: ${Spacings.SPACING_2B};
  border-radius: 50%;
  background-color: var(--color-i-blue);
  position: absolute;
  top: 25%;
  left: 12%;
  border: 1.6px solid var(--color-text-tertiary);
  &:hover {
    background-color: var(--color-i-blue) !important;
  }
`;

const getNavigationMap = ({ route, handleClick, selectedNav, goalAs }) => {
  const navigationMap = [
    {
      label: 'Home',
      dataId: plusNavBarItems.ENROLLMENTS_BATCH,
      isSelected:
        route === `${goalHref}/${batchGroupNave.home}` ||
        selectedNav === plusNavBarItems.ENROLLMENTS_BATCH,
      href: `${goalHref}/${batchGroupNave.home}`,
      as: `${goalAs}/${batchGroupNave.home}`,
      itemHandleClick: handleClick(
        plusNavBarItems.ENROLLMENTS,
        `${goalHref}/${batchGroupNave.home}`,
        `${goalAs}/${batchGroupNave.home}`
      ),
      key: plusNavBarItems.ENROLLMENTS_BATCH,
      isShown: true,
      icon: StyleSideNavHome,
      hovColor: 'var(--color-i-blue-18)'
    },
    {
      label: 'Browse',
      dataId: plusNavBarItems.UPCOMING_BATCHES,
      isSelected:
        route === `${goalHref}/${batchGroupNave.browse}` ||
        selectedNav === plusNavBarItems.UPCOMING_BATCHES,
      href: `${goalHref}/${batchGroupNave.browse}`,
      as: `${goalAs}/${batchGroupNave.browse}`,
      itemHandleClick: handleClick(
        plusNavBarItems.UPCOMING_BATCHES,
        `${goalHref}/${batchGroupNave.browse}`,
        `${goalAs}/${batchGroupNave.browse}`
      ),

      key: plusNavBarItems.UPCOMING_BATCHES,
      isShown: true,
      icon: StyBrowseIcon,
      hovColor: 'var(--color-i-turquoise-6)'
    },
    {
      label: 'Saved',
      dataId: plusNavBarItems.SAVED,
      isSelected:
        route === `${goalHref}/saved` || selectedNav === plusNavBarItems.SAVED,
      href: `${goalHref}/saved`,
      as: `${goalAs}/saved`,
      itemHandleClick: handleClick(
        plusNavBarItems.SAVED,
        `${goalHref}/saved`,
        `${goalAs}/saved`
      ),
      key: plusNavBarItems.SAVED,
      isShown: true,
      icon: StySideNavSaved,
      hovColor: 'var(--color-i-red-1)'
    }
  ];
  return navigationMap;
};

const PlatformNavItems = ({ handleClick, selectedNav, goalAs }) => {
  const { route } = useRouter();

  const tabsMap = getNavigationMap({
    route,
    handleClick,
    selectedNav,
    goalAs
  });
  return (
    <>
      {tabsMap
        .filter((item) => item && item.isShown)
        .map((item) => {
          return (
            <Item
              $selected={item.isSelected}
              data-id={item.dataId}
              onClick={item.itemHandleClick}
              hovColor={item.hovColor}
              href={item.href}
              as={item.as}
              key={item.key}
              prefetch
            >
              {item.showDot && <IconDot />}
              {item.icon ? (
                <item.icon
                  size="20px"
                  color={
                    item.isSelected
                      ? item.hovColor
                      : 'var(--color-text-secondary)'
                  }
                  className="sideNavIcon"
                />
              ) : null}
              {item.label}
            </Item>
          );
        })}
    </>
  );
};

export default PlatformNavItems;

PlatformNavItems.propTypes = {
  handleClick: PropTypes.func.isRequired,
  selectedNav: PropTypes.string
};
PlatformNavItems.defaultProps = {
  selectedNav: ''
};
