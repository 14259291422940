import Icon from './NavIcon';

const StoreIcon = (props) => (
  <Icon
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
    opacity=".3"
    cx="13.0007"
    cy="6.99997"
    r="4.5"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6322 6.18057H5.36777C4.9652 6.18057 4.63214 6.49224 4.60541 6.89328L3.99735 16.0095C3.93853 16.8911 4.63825 17.6389 5.52207 17.6389H14.4779C15.3617 17.6389 16.0614 16.8911 16.0026 16.0095L15.3946 6.89328C15.3678 6.49224 15.0348 6.18057 14.6322 6.18057Z"
      fill="#8698A6"
      stroke="#8698A6"
      strokeWidth="1.14583"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.9444 14.5833H13.0555"
      stroke="white"
      fill="white"
      strokeWidth="1.14583"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ fill: 'transparent' }}
    />
    <path
      d="M12.2924 7.70835V4.6528V4.6528V4.6528C12.2924 3.38703 11.2665 2.36113 10.0007 2.36113H9.99998C8.73422 2.36113 7.70831 3.38703 7.70831 4.6528V4.6528V4.6528V7.70835"
      stroke="#8698A6"
      strokeWidth="1.14583"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ fill: 'transparent' }}
    />
  </Icon>
);

export default StoreIcon;
