import Icon from './NavIcon';

const DoubtsIcon = (props) => (
  <Icon
    {...props}
    opacity=".3"
    pathFillRule="evenodd"
    pathClipRule="evenodd"
    gpath="M38.7288 62.7278C32.7763 68.6802 23.6922 69.5923 16.78 65.4641L11.6959 65.7317C10.8911 65.7741 10.2269 65.1099 10.2693 64.3051L10.5368 59.2216C6.40751 52.3092 7.31931 43.2242 13.2722 37.2712C16.2774 34.2661 20.0808 32.5456 24 32.1099V32H25.8124C25.9378 31.9987 26.0632 31.9987 26.1886 32H64V44H42.9762C45.209 50.3319 43.7932 57.6633 38.7288 62.7278Z"
    path="M64.9229 21.3898C73.6251 31.1721 74.2181 45.359 67.1829 55.7178L67.1366 63.7133C67.1296 64.9304 66.0697 65.8732 64.8601 65.7385L56.9133 64.8532C45.8058 70.6335 31.7849 68.3922 23.083 58.6102C12.8048 47.0564 13.839 29.3581 25.3928 19.08C36.9466 8.80192 54.6448 9.83606 64.9229 21.3898ZM33.0029 44C34.6598 44 36.0029 42.6569 36.0029 41C36.0029 39.3431 34.6598 38 33.0029 38C31.3461 38 30.0029 39.3431 30.0029 41C30.0029 42.6569 31.3461 44 33.0029 44ZM48.0081 41C48.0081 42.6569 46.6649 44 45.0081 44C43.3512 44 42.0081 42.6569 42.0081 41C42.0081 39.3431 43.3512 38 45.0081 38C46.6649 38 48.0081 39.3431 48.0081 41ZM57.0002 44C58.6571 44 60.0002 42.6569 60.0002 41C60.0002 39.3431 58.6571 38 57.0002 38C55.3434 38 54.0002 39.3431 54.0002 41C54.0002 42.6569 55.3434 44 57.0002 44Z"
  />
);

export default DoubtsIcon;
