import Icon from './NavIcon';

const SideNavEnrollments = (props) => (
  <Icon
    {...props}
    opacity=".3"
    gpath="M15.015 16.6748C15.3049 14.432 16.9917 12.6233 19.2088 12.1778C32.9324 9.42017 47.0676 9.42017 60.7912 12.1778C63.0083 12.6233 64.695 14.432 64.985 16.6748L66.0115 24.6166C67.3317 34.8297 67.3317 45.1703 66.0115 55.3834L64.985 63.3252C64.695 65.568 63.0083 67.3767 60.7912 67.8222C47.0676 70.5798 32.9324 70.5798 19.2088 67.8222C16.9917 67.3767 15.3049 65.568 15.015 63.3252L13.9885 55.3834C12.6683 45.1703 12.6683 34.8297 13.9885 24.6166L15.015 16.6748Z"
    path="M17.9902 18.9029C14.41 19.375 11.5298 22.0783 10.8319 25.6214L10.6916 26.3339C8.91401 35.3583 8.91401 44.6428 10.6916 53.6673L10.8319 54.3797C11.5298 57.9229 14.41 60.6261 17.9902 61.0982C32.5999 63.0248 47.3993 63.0248 62.009 61.0982C65.5893 60.6261 68.4695 57.9229 69.1674 54.3797L69.3077 53.6673C71.0852 44.6428 71.0852 35.3583 69.3077 26.3339L69.1674 25.6214C68.4695 22.0783 65.5893 19.375 62.009 18.9029C47.3993 16.9764 32.5999 16.9764 17.9902 18.9029ZM48.9669 37.9178C50.3442 38.9716 50.3442 41.0276 48.9669 42.0814C44.8043 45.266 40.1563 47.7761 35.1967 49.5178L34.2906 49.836C32.5572 50.4447 30.7263 49.2851 30.4916 47.5159C29.836 42.5727 29.836 37.4266 30.4916 32.4833C30.7263 30.7141 32.5572 29.5545 34.2906 30.1632L35.1967 30.4814C40.1563 32.2231 44.8043 34.7332 48.9669 37.9178Z"
  />
);

export default SideNavEnrollments;
