/* eslint-disable no-param-reassign */
import { State, UseBoundStore } from 'zustand';
import shallow from 'zustand/shallow';

interface Selectors<StoreType> {
  use: {
    [key in keyof StoreType]: () => StoreType[key];
  };
}

// Selector ref: https://github.com/pmndrs/zustand/wiki/Auto-Generating-Selectors
// Shallow store ref: https://github.com/pmndrs/zustand#selecting-multiple-state-slices
// To get the property/action: const propertyName = useStore.use.propertyName()
function createStoreSelectors<StoreType extends State>(
  store: UseBoundStore<StoreType>
) {
  (store as any).use = {};

  Object.keys(store.getState()).forEach((key) => {
    const selector = (state: StoreType) => state[key as keyof StoreType];
    (store as any).use[key] = () => store(selector, shallow);
  });

  return store as UseBoundStore<StoreType> & Selectors<StoreType>;
}

export default createStoreSelectors;
